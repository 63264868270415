import { createContext, ReactNode, SetStateAction, useState } from "react";

interface AuthProviderProps {
  children: ReactNode;
}

interface AUTH_STATE{
  access_token?: string;
}

interface AUTH_CONTEXT_TYPE {
  auth: AUTH_STATE;
  setAuth: React.Dispatch<React.SetStateAction<AUTH_STATE>>;
}

const empty_auth: AUTH_STATE = {access_token: undefined}

const AuthContext = createContext<AUTH_CONTEXT_TYPE>({
  auth: empty_auth,
  setAuth: function (value: SetStateAction<AUTH_STATE>): void {
    throw new Error("Function not implemented.");
  }
});

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [auth, setAuth] = useState<AUTH_STATE>(empty_auth);
  console.log(auth);
  return(
    <AuthContext.Provider 
      value={{auth, setAuth}}>
        {children}
    </AuthContext.Provider>
  )
}

export default AuthContext;