import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext";
import {  ReactElement, useEffect, useState } from "react";
import { useToast } from "../utils/toast_context";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
export interface INSERT_RECIPIENT_OBJECT{
  job_listing_id: number,
  recipient_name?: string;
  recipient_position?: string;
  recipient_street_address?: string;
  recipient_city?: string;
  recipient_country?: string;
}
interface RECIPIENT_INPUT_PROPS{
  visible: boolean;
  setVisible: (visible: boolean) => void;
  job_listing_id: number;
  onComplete: (Recipient: INSERT_RECIPIENT_OBJECT)=>(void | Promise<void>) | undefined;
}


export function RecipientInput({visible, setVisible, job_listing_id, onComplete}:RECIPIENT_INPUT_PROPS):ReactElement{
  const { showToast } = useToast(); 
  const axios_private = useAxiosPrivate()

  const [recipientInfo, setRecipientInfo] = useState<INSERT_RECIPIENT_OBJECT>({
    job_listing_id,
    recipient_name: undefined,
    recipient_position: undefined,
    recipient_street_address: undefined,
    recipient_city: undefined,
    recipient_country: undefined,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(()=>{getRecipientInfo();}, [])

  const getRecipientInfo = async () => {
    try {
      const success = await axios_private
        .get('messages/recipient', {params: {job_listing_id}})
      setRecipientInfo({...recipientInfo, ...(success.data || {})});
      return success;
    } catch (error) {
      showToast({
        severity: 'error', 
        summary: `Failed to save user info`
      });
      console.error(error);
      return null;
    }
  }

  const sendRecipientInfo = async () => {
    try {
      const success = await axios_private
        .post('messages/recipientUpdate', recipientInfo)
      return success;
    } catch (error) {
      showToast({
        severity: 'error', 
        summary: `Failed to save user info`
      });
      console.error(error);
      return null;
    }
  }

  const onClose = async () => {
    await sendRecipientInfo().then((success)=>{
      success&&onComplete&&onComplete(recipientInfo);
      success&&setVisible(false);
    });
  }

  return (
    <Dialog
      style={{ width: '70vw' }} 
      header={<span><i className="pi pi-address-book"/> Recipient</span>} 
      visible={visible} 
      onHide={()=>onClose()} >
      <form>
        <div>
          <label htmlFor="name">Name:  </label>
          <InputText id="name" value={recipientInfo?.recipient_name||''} onChange={(e)=>setRecipientInfo({...recipientInfo, recipient_name:e.target.value||''})} />
        </div>
        <div>
          <label htmlFor="career_position" className="career_position">Career Position: </label>
          <InputText id="career_position" value={recipientInfo?.recipient_position||''} onChange={(e)=>setRecipientInfo({...recipientInfo, recipient_position:e.target.value||''})} ></InputText>
        </div>
        <div>
          <label htmlFor="street_address" className="street_address">Street Address: </label>
          <InputText id="street_address" value={recipientInfo?.recipient_street_address ||''} onChange={(e)=>setRecipientInfo({...recipientInfo, recipient_street_address:e.target.value||''})} ></InputText>
        </div>
        <div>
          <label htmlFor="city" className="city">City: </label>
          <InputText id="city" value={recipientInfo?.recipient_city ||''} onChange={(e)=>setRecipientInfo({...recipientInfo, recipient_city:e.target.value||''})} ></InputText>
        </div>
        <div>
          <label htmlFor="country" className="country">Country: </label>
          <InputText id="country" value={recipientInfo?.recipient_country ||''} onChange={(e)=>setRecipientInfo({...recipientInfo, recipient_country:e.target.value||''})} ></InputText>
        </div>   
      </form>
    </Dialog>
    );
}