import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Stepper, StepperRefAttributes } from 'primereact/stepper';
import { StepperPanel } from 'primereact/stepperpanel';
import { useEffect, useRef, useState } from 'react';
import { INSERT_USER_OBJECT } from './UserInfo';
import { useToast } from '../utils/toast_context';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { UserInfoForm } from './UserInfoForm';
import Generate from './Generate';
import DropDownTailWind, { OptionsObject } from '../utils/DropDown';

interface StepByStepProp {
  width: string
}

export interface PromptObject extends OptionsObject {
  prompt_id?: number;
  prompt?: string;
  title?: string
}

export interface CoverLetterObject extends OptionsObject {
  cover_letter_id?: number;
  cover_letter?: string;
  title?: string
}

type ObjectType = 'prompt' | 'example_prompt' | 'example_cover_letter';

export interface StepByStepItem {
  user_type: INSERT_USER_OBJECT
  prompt: PromptObject;
  example_prompt: PromptObject;
  example_cover_letter: CoverLetterObject;
  completed: boolean;
}

export default function StepByStep({width}: StepByStepProp){
  const stepperRef = useRef<StepperRefAttributes>(null);
  const [prompts, setPrompts] = useState<PromptObject[]>([]);
  const [examplePrompts, setExamplePrompts] = useState<PromptObject[]>([]);
  const [exampleCoverLetters, setExampleCoverLetters] = useState<CoverLetterObject[]>([]);
  const { showToast } = useToast();
  const axios_private = useAxiosPrivate();

  const [item, setItem] = useState<StepByStepItem>({
    user_type: {},
    prompt: {},
    example_prompt: {},
    example_cover_letter: {},
    completed: false,
  });

  const [activeStep, setActiveStep] = useState<number>(0); 

  const deletePrompt = async (prompt_id: number) => {
    try {
      await axios_private
        .post('step/deletePrompt', {prompt_id})
        .then(()=>getPrompts());
    } catch (error) {
      showToast({
        severity: 'error', 
        summary: `Failed delete prompt`
      });
      console.error(error);
    }
  }

  const deleteExamplePrompt = async (prompt_id: number) => {
    try {
      await axios_private
        .post('step/deleteExamplePrompt', {prompt_id})
        .then(()=>getExamplePrompts());
    } catch (error) {
      showToast({
        severity: 'error', 
        summary: `Failed delete example prompt`
      });
      console.error(error);
    }
  }
  
  const deleteExampleCoverLetter = async (cover_letter_id: number) => {
    try {
      await axios_private
        .post('step/deleteExampleCoverLetter', {cover_letter_id})
        .then(()=>getExampleCoverLetter());
    } catch (error) {
      showToast({
        severity: 'error', 
        summary: `Failed delete cover letter`
      });
      console.error(error);
    }
  }

  const getPrompts = async (cb?:(result: PromptObject)=>void) => {
    try {
      const results = await axios_private.get('step/readPrompts');
      if (Array.isArray(results.data)) {        
        setPrompts([{title: 'New prompt'},...results.data]);
        (!results.data.length || item.prompt?.prompt_id || item.prompt?.prompt_id === 0) || cb ? cb&&cb(results.data.at(0)):setItem({...item, prompt: results.data.at(0)});
        !results.data.length && cb ? cb&&cb(results.data.at(0)):setItem({...item, prompt: {title: 'New Prompt'}});
      }

    } catch (error) {
      showToast({
        severity: 'error', 
        summary: `Failed to get prompt`,
        life: undefined,
        position: undefined,
        detail: undefined
      });
      console.error(error);
    }
  }

  const getExamplePrompts = async (cb?:(result: PromptObject)=>void) => {
    try {
      const results = await axios_private.get('step/readExamplePrompts');
      if (Array.isArray(results.data)) {
        setExamplePrompts([{title: 'New Example Prompt'},...results.data]);
        (!results.data.length || item.example_prompt?.prompt_id || item.example_prompt?.prompt_id === 0) || 
          cb ? cb&&cb(results.data.at(0)):setItem({...item, example_prompt: results.data.at(0)});
        !results.data.length && cb ? cb&&cb(results.data.at(0)):setItem({...item, example_prompt: {title: 'New Example Prompt'}});
      }
    } catch (error) {
      showToast({
        severity: 'error', 
        summary: `Failed to get example prompt`
      });
      console.error(error);
    }
  }

  const getExampleCoverLetter = async (cb?:(result: CoverLetterObject)=>void) => {
    try {
      const results = await axios_private.get('step/readExampleCoverLetter');
      if (Array.isArray(results.data)) {
        setExampleCoverLetters([{title: 'New Example Cover Letter'},...results.data]);
        (!results.data.length || item.example_cover_letter?.cover_letter_id || item.example_cover_letter?.cover_letter_id === 0) || 
          cb ? cb&&cb(results.data.at(0)):setItem({...item, example_cover_letter: results.data.at(0)});
        !results.data.length && cb ? cb&&cb(results.data.at(0)):setItem({...item, example_cover_letter: {title: 'New Example Prompt'}});
      }

    } catch (error) {
      showToast({
        severity: 'error', 
        summary: `Failed to get example prompt`
      });
      console.error(error);
    }
  }

  useEffect(()=>{
    getStandardInput().then(()=>{let prompt: PromptObject = {};
    let example_prompt: PromptObject = {};
    let example_cover_letter: CoverLetterObject = {}
    Promise.all([
      getPrompts((result)=>{prompt = result}), 
      getExamplePrompts((result)=>{example_prompt = result}),
      getExampleCoverLetter((result)=>{example_cover_letter = result})
    ]).then(()=>setItem({...item, prompt, example_prompt, example_cover_letter}))})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ButtonsBack = (disabled: boolean, cb?: Function) => (<div className="flex pt-4 justify-content-end">
    <Button 
      label="Back" 
      icon="pi pi-arrow-left" 
      iconPos="right" 
      disabled={disabled}
      outlined
      rounded
      onClick={() => {
        cb ? cb(()=>{
          getPrompts();
          stepperRef?.current?.prevCallback();
        }) : stepperRef?.current?.prevCallback();
      }} 
    />
    <Button 
      label="Next" 
      icon="pi pi-arrow-right" 
      iconPos="right" 
      disabled={disabled}
      outlined
      rounded      
      onClick={() => {
        cb ? cb(()=>{          
          getPrompts();
          stepperRef?.current?.nextCallback();
        }): stepperRef?.current?.nextCallback();
      }}
    />
  </div>);

  const savePrompt = async (success?: Function) => {
    try {
      await saveStandardInput();
      if (item.prompt?.prompt && item.prompt?.title)
        if (item.prompt?.prompt_id || item.prompt?.prompt_id === 0)
          await axios_private.post('step/updatePrompt', {title: item.prompt?.title, prompt: item.prompt?.prompt, prompt_id: item.prompt?.prompt_id});
        else {
          await axios_private.post('step/createPrompt', {title: item.prompt?.title, prompt: item.prompt?.prompt});
        }
        await getPrompts()
        success&&success()
    } catch (error) {
      showToast({
        severity: 'error', 
        summary: `Failed to save prompt`,
        life: undefined,
        position: undefined,
        detail: undefined
      });
      console.error(error);
    }
  }

  const saveExamplePrompt = async (success?: Function) => {
    try {
      await saveStandardInput();
      if (item.example_prompt?.prompt && item.example_prompt?.title)
        if (item.example_prompt?.prompt_id || item.example_prompt?.prompt_id === 0)
          await axios_private.post('step/updateExamplePrompt', {title: item.example_prompt?.title, prompt: item.example_prompt?.prompt, prompt_id: item.example_prompt?.prompt_id});
        else {
          await axios_private.post('step/createExamplePrompt', {title: item.example_prompt?.title, prompt: item.example_prompt?.prompt});
        }
        await getExamplePrompts();
        success&&success()
    } catch (error) {
      showToast({
        severity: 'error', 
        summary: `Failed to save prompt`,
        life: undefined,
        position: undefined,
        detail: undefined
      });
      console.error(error);
    }
  }

  const saveCoverLetter = async (success?: Function) => {
    try {
      await saveStandardInput();
      if (item.example_cover_letter?.cover_letter && item.example_cover_letter?.title)
        if (item.example_cover_letter?.cover_letter_id || item.example_cover_letter?.cover_letter_id === 0)
          await axios_private.post('step/updateExampleCoverLetter', {title: item.example_cover_letter?.title, cover_letter: item.example_cover_letter?.cover_letter, cover_letter_id: item.example_cover_letter?.cover_letter_id});
        else {
          await axios_private.post('step/createExampleCoverLetter', {title: item.example_cover_letter?.title, cover_letter: item.example_cover_letter?.cover_letter});
        }
        await getExampleCoverLetter();
        success&&success()
    } catch (error) {
      showToast({
        severity: 'error', 
        summary: `Failed to save prompt`,
        life: undefined,
        position: undefined,
        detail: undefined
      });
      console.error(error);
    }
  }

  const getStandardInput = async () => {
    try {
      // send to server
      const standard_input = await axios_private.get('step/readStandardInput').then((result)=>result.data);
      if (standard_input.completed) {
        setActiveStep(4);
        stepperRef.current?.setActiveStep(4);
      }
      setItem({
        ...item,
        prompt: {
          prompt: standard_input.prompt,
          title: standard_input.prompt_title, 
          prompt_id: standard_input.prompt_id,
        },
        example_prompt: {
          prompt: standard_input.example_prompt,
          title: standard_input.example_prompt_title, 
          prompt_id: standard_input.example_prompt_id,          
        },        
        example_cover_letter: {
          cover_letter: standard_input.example_cover_letter,
          title: standard_input.example_cover_letter_title, 
          cover_letter_id: standard_input.example_cover_letter_id,          
        },
        completed: !!standard_input.completed
      });
    } catch (error) {
      showToast({
        severity: 'error', 
        summary: `Failed to get standard input`,
        life: undefined,
        position: undefined,
        detail: undefined
      });
      console.error(error);
    }
  }

  const saveStandardInput = async () => {
    try {
      // check for completion of standard in
      const completed = 
        (!item.example_cover_letter?.cover_letter_id && item.example_cover_letter?.cover_letter_id !==0) || 
        (!item.example_prompt?.prompt_id && item.example_prompt?.prompt_id !== 0) || 
        (!item.prompt?.prompt_id && item.prompt?.prompt_id !==0 );
      
      setItem({...item, completed});
      // send to server
      await axios_private.post('step/createStandardInput', {
        example_cover_letter_id: item.example_cover_letter?.cover_letter_id, 
        example_cover_letter: item.example_cover_letter?.cover_letter,
        example_cover_letter_title: item.example_cover_letter?.title, 
        example_prompt: item.example_prompt?.prompt,
        example_prompt_title: item.example_prompt?.title,
        example_prompt_id: item.example_prompt?.prompt_id,
        prompt: item.prompt?.prompt,
        prompt_id: item.prompt?.prompt_id,
        prompt_title: item.prompt?.title,
        completed
      });
    } catch (error) {
      showToast({
        severity: 'error', 
        summary: `Failed to save standard input`,
        life: undefined,
        position: undefined,
        detail: undefined
      });
      console.error(error);
    }
  }

  const promptDeleteTemplate = (option:PromptObject) => {
    const prompt_id = typeof option.prompt_id === 'number' ? option.prompt_id: null
    return (
        <div className="flex align-items-center">
            <div>{option?.title || ''}</div>
            {(prompt_id || prompt_id===0)?<Button icon='pi pi-trash' onClick={()=>deletePrompt(prompt_id)} outlined rounded />:null}
        </div>
    );
  }

  const examplePromptDeleteTemplate = (option:PromptObject) => {
    const prompt_id = typeof option.prompt_id === 'number' ? option.prompt_id: null
    return (
        <div className="flex align-items-center">
            <div>{option?.title || ''}</div>
            {(prompt_id || prompt_id===0)?<Button icon='pi pi-trash' onClick={()=>deleteExamplePrompt(prompt_id)} outlined rounded />:null}
        </div>
    );
  }

  const exampleCoverLetterDeleteTemplate = (option:CoverLetterObject) => {
    const cover_letter_id = typeof option?.cover_letter_id === 'number' ? option?.cover_letter_id: null
    return (
        <div className="flex align-items-center">
            <div>{option?.title || ''}</div>
            {(cover_letter_id || cover_letter_id===0)?<Button icon='pi pi-trash' onClick={()=>deleteExampleCoverLetter(cover_letter_id)} outlined rounded />:null}
        </div>
    );
  }

  function selectItemsForPrompt<T>(options: T[], object_name: ObjectType, value: number):T | undefined{
    return options.find(
      (result) => {
        if (object_name === 'example_cover_letter') {
          return (result as CoverLetterObject)?.cover_letter_id === value;
        }
        return (result as PromptObject).prompt_id === value;
      }
    );
  }

  const promptAndCoverLetterTemplate = (object_name: ObjectType) => {
    return (
      <div>
        <div className='form flex col-6'>
          <label htmlFor={`select-${object_name}`} className="select-prompt">
            {object_name==='prompt'?'Select Prompt:':(object_name === 'example_prompt'?'Select Example Prompt:':'Select Example Cover Letter:')}
          </label>
          <DropDownTailWind
            id={`select-${object_name}`}
            value={object_name === 'example_cover_letter' ? item[object_name]?.cover_letter_id : item[object_name]?.prompt_id}
            options={object_name === 'example_cover_letter' ? exampleCoverLetters : (object_name === 'example_prompt' ? examplePrompts : prompts)}
            optionLabel='title'
            optionValue={object_name === 'example_cover_letter' ? 'cover_letter_id' : 'prompt_id'}
            itemTemplate={object_name === 'prompt' ? promptDeleteTemplate : (object_name === 'example_prompt' ? examplePromptDeleteTemplate : exampleCoverLetterDeleteTemplate)}
            onChange={(e) => {
              if (object_name === 'example_cover_letter') {
                const selectedItem = selectItemsForPrompt(exampleCoverLetters, 'example_cover_letter', e.value);
                setItem({
                  ...item,
                  [object_name]: selectedItem || item[object_name]
                });
              } else {
                const selectedItem = selectItemsForPrompt(object_name === 'prompt' ? prompts : examplePrompts, object_name, e.value);
                setItem({
                  ...item,
                  [object_name]: selectedItem || item[object_name]
                });
              }
            } }/>
        </div>
        <div className='form flex col-6'>
          <label 
            htmlFor={`${object_name}-title`} 
            className={`${object_name}-label`} 
          >
            {object_name === 'example_cover_letter'?'Cover Letter Title: ':'Prompt Title: '} 
          </label>
          <InputText 
            id={`${object_name}-title`}  
            value={item[object_name]?.title || ''} 
            onChange={(e)=>
              setItem({
                ...item, 
                [object_name]: {
                  ...item[object_name], 
                  title: e.target.value}
              })}
          />
        </div>
        <label htmlFor={`${object_name}-prompt`}  className={`${object_name}-prompt-label`} >
          {object_name === 'example_cover_letter'?'Cover Letter: ':'Prompt: '}
        </label>
        <InputTextarea 
          id={`${object_name}-prompt`} 
          value={(object_name === 'example_cover_letter'?item[object_name]?.cover_letter:item[object_name]?.prompt) || ''} 
          rows={10} 
          style={{height: '70%', width: '100%'}}
          onChange={(e)=>
            setItem({
              ...item, 
              [object_name]: {
                ...item[object_name], 
                [object_name === 'example_cover_letter'?'cover_letter':'prompt']: e.target.value
              }
            })}
        />
      </div>
    )
  }

  return (
    <div className='mt-20' style={{ width }}>
      <Stepper 
        ref={stepperRef} 
        activeStep={activeStep} 
        onChangeStep={(e)=>{
          setActiveStep(e.index);
          stepperRef.current?.setActiveStep(e.index);
        }}
      >
        <StepperPanel header="Complete Profile">
            <div className="flex flex-column h-12rem">
              <h2 className='text-xl mb-5 font-bold'>Complete Profile</h2>
              <UserInfoForm onSave={()=>{}} setUser={(user)=>setItem({...item, user_type: user})} />
            </div>
            {ButtonsBack(false)}
        </StepperPanel>
        <StepperPanel header="Prompt">
            <div className="form" style={{height: '100%', width: '100%'}}>
              <h2 className='text-xl mb-5 font-bold'>Edit Prompt</h2>
              {promptAndCoverLetterTemplate('prompt')}
            </div>
          {ButtonsBack((!item.prompt?.title || !item.prompt?.prompt), savePrompt)}
        </StepperPanel>
        <StepperPanel header="Cover Letter Example">
            <div className="form" style={{height: '100%', width: '100%'}}>
              <h2 className='text-xl mb-5 font-bold'>Cover Letter Example</h2>
              {promptAndCoverLetterTemplate('example_cover_letter')}
            </div>
          {ButtonsBack((!item.example_cover_letter?.title || !item.example_cover_letter?.cover_letter), saveCoverLetter)}
        </StepperPanel>
        <StepperPanel header="Prompt Example">
            <div className="form" style={{height: '100%', width: '100%'}}>
              <h2 className='text-xl mb-5 font-bold'>Prompt Example</h2>
              {promptAndCoverLetterTemplate('example_prompt')}
            </div>
          {ButtonsBack((!item.example_prompt?.title || !item.example_prompt?.prompt), saveExamplePrompt)}
        </StepperPanel>
        <StepperPanel header="Generate Cover Letters">
          <Generate 
            exampleCoverLetters={exampleCoverLetters} 
            examplePrompts={examplePrompts} 
            prompts={prompts} 
            standardInput={item} 
          />
          {ButtonsBack(false)}
        </StepperPanel>
      </Stepper>
    </div>
  );
 
}