import { Button } from "primereact/button";
import { useEffect, useState } from "react";
import { useToast } from "../utils/toast_context";
import client from "../utils/axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import useRefreshToken from "../hooks/useRefreshToken";


export async function sha256(message: string) {
  // From https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message);                    

  // hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string                  
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
}

function Login(): JSX.Element {
  interface LOGIN_ITEM{
    email?: string;
    password?: string;
  }
  const [item, setItem] = useState<LOGIN_ITEM>({});

  const { auth, setAuth } = useAuth();

  const navigate = useNavigate();

  
  const refresh = useRefreshToken();
  
  const location = useLocation();
  const from = location?.state?.from?.pathname ||'/app';

  const { showToast } = useToast(); 

  useEffect(()=> {
    auth.access_token && navigate(from);
    auth.access_token || refresh().catch(()=>null).then((token)=>token && navigate(from));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const login = async () => {
    const email = item.email;
    const password = typeof item.password === 'string'?await sha256(item.password):'';
    if (typeof email === 'string' || typeof item.password === 'string'){
      try {
        const response_jwt = await client.get('user/login', {params: { email, password}}).then((data)=>data.data);
        if (!response_jwt || !response_jwt.access_token || !response_jwt.code) {
          throw new Error(`Faulty response ${response_jwt}`);
        }
        setAuth({access_token: response_jwt.access_token});
        setItem({});
        navigate(from);
      } catch (error) {
        showToast({
          severity: 'error', 
          summary: `Failed to login`
        })
        console.error(error)
      }
    }
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 bg-white rounded-xl shadow-lg">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="flex justify-center">
            <a href="/">
              <img className="h-20" src="/logo_black.svg" alt="logo" />
            </a>
          </div>
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
              <input id="email"
                  name="email"
                  type="email"
                  required
                  autoComplete="email"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" 
                  value={item.email} 
                  onChange={(e)=>setItem({...item, email: e.target.value})}
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                  Password
                </label>
                {/* <div className="text-sm">
                  <a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">
                    Forgot password?
                  </a>
                </div> */}
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  autoComplete="current-password"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  value={item.password} 
                  onChange={(e)=>setItem({...item, password: e.target.value})}
                />
              </div>
            </div>

            <div>
              <Button
                type="submit"
                className="flex w-full mt-2"
                label="Sign In"
                onClick={()=> login()} 
                disabled={!item.email || !item.password}
                outlined
                rounded
              >
              </Button>
            </div>
            <p className="mt-2">Not a User?{' '}
            <Link 
              className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500" to="/register">Create a free account
            </Link>
            </p>
        </div>
      </div>
    </div>
)
}

export default Login;