import axios from 'axios';

const client = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

export default client;

export const client_private = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {'Content-Type': 'application/json'}
});

