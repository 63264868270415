import { Dispatch, ReactNode, SetStateAction, useState } from "react";
import { Link, useLocation } from "react-router-dom";



export interface OptionsObject{
  name?: string | number;    
  value?: string | number;
  [key: string]: string | number | ReactNode;
}

interface DropDownProps<O, t>{
  options: O[];
  value: t | undefined;
  onChange: Dispatch<SetStateAction<any>>;
  optionLabel?: keyof O;
  optionValue?: keyof O;
  emptyMessage?: string;
  itemTemplate?: (option: O) => JSX.Element
  id?: string;
  displayLength?: number;
  invalid?: boolean;
  disabled?: boolean
}


export default function DropDownTailWind<O=OptionsObject, t=any>({options, value, onChange, optionLabel, optionValue, emptyMessage, id, itemTemplate, invalid, disabled, displayLength=15}:DropDownProps<O, t>){
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);  

  const location = useLocation();

  const selectedOption = options.find((option) => option[optionValue || 'value' as keyof O] === value);
  const displayText: string = selectedOption 
    ? (selectedOption[optionLabel || 'name' as keyof O] as string)
    : (emptyMessage || 'Select ...');
  const shortenedDisplayText: string = typeof displayText=== 'string' && displayText.length>displayLength ?`${displayText.substring(0,displayLength)}...`:displayText
  return (
    <div className="relative inline-block text-left" id={id}>
      <button 
        id="dropdownDefaultButton" 
        data-dropdown-toggle="dropdown" 
        className="text-black border-solid border-1 border-gray-300 bg-white hover:bg-gray-50 focus:ring-4 focus:outline-none focus:border-black font-medium rounded-md text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-gray-600dark:focus:ring-gray-700" 
        type="button"
        disabled={disabled}
        onClick={()=>!disabled&&setDropdownVisible(!dropdownVisible)}
        style={{...invalid?{borderColor: 'red'}:{}, ...disabled?{color: 'gray'}:{}}}
        >
           {shortenedDisplayText}
        <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
        </svg>
      </button>
      {
        dropdownVisible&&<div 
          id="dropdown" 
          className="absolute right-0 z-10 mt-2 w-full bg-white divide-y divide-gray-100 rounded-md shadow dark:bg-gray-700"
        >
          <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
          {options.map((option)=>( <li>
            <Link 
              to={location?.state?.from?.pathname} 
              onClick={(e)=>{
                onChange({...e, value: option[optionValue || 'value' as keyof O] as t});
                setDropdownVisible(false);
              }} 
              className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-black">{
                itemTemplate ? itemTemplate(option) : option[optionLabel || 'name' as keyof O] as ReactNode}
              </Link>
          </li>))}
        </ul>
        </div>
      }
    </div>);
}