import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

function LandingPage(): JSX.Element {
  const navigate = useNavigate();

  function techStackElement(img_src: string, img_alt: string, description: string) {
    return (
      <div className="group relative bg-gray-200 p-6 rounded-lg transition-all duration-300 hover:shadow-lg w-72 h-72 flex items-center justify-center">
      <div className="absolute inset-0 flex items-center justify-center">
        <p className="text-black text-center p-4 opacity-5 group-hover:opacity-100 transition-opacity duration-300">
          {description}
        </p>
      </div>
      <img 
        className="max-w-full max-h-full object-contain transition-opacity duration-300 group-hover:opacity-0"
        src={img_src} 
        alt={img_alt} 
      />
    </div>
    );
  }

  return (
    <div className="min-h-screen bg-cover relative flex flex-col" style={{ backgroundImage: "url('/background.jpg')" }}>
  {/* Header */}
  <div className="flex p-1 h-10">
    {/* Logo */}
    <div className="flex absolute left-0">
      <img className="w-3" src="/logo_white.svg" alt="logo" />
    </div>
    
    {/* Sign In and Sign Up Buttons */}
    <div className="flex-none absolute right-0 mr-2 mt-1">
      <Button className="mr-4 text-white" text rounded label="Sign In" onClick={() => navigate('/login')} />
      <Button className=" text-white" label="Sign Up" text rounded onClick={() => navigate('/register')} />
    </div>
  </div>

  {/* Content wrapper */}
  <div className="flex-grow flex flex-col mt-5">
    {/* Body Section */}
    <div className="flex flex-col justify-center items-start mt-32 mb-32">
      {/* Title text */}
      <div className="w-5/6 md:w-2/3 lg:w-1/2 ml-4">
        <h1 className="text-4xl md:text-5xl lg:text-6xl text-white font-bold mb-4">
          Cover letters that capture your unique strengths, experiences
        </h1>
        <hr className="border-white mb-4" />
        <p className="text-base md:text-lg text-white">
          Our solution leverages <strong className="font-bold">Retrieval-Augmented Generation (RAG)</strong> to pull from your own personal documents—like resumes, past cover letters, and project descriptions, papers, thesis…—to ensure every letter is tailored to your unique and relevant experiences and achievements.
        </p>
      </div>
    </div>
    {/* Tech Stack */}
    <div className="bg-white opacity-95 rounded-lg p-8 w-full mt-16 mb-16">
          <h2 className="text-3xl font-bold mb-6">Tech Stack</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Embedding Text LLM */}
            {techStackElement('/Neural_network.svg','neural network', "Snowflake's Arctic is a Light weight and open-source Transformer that allows low cost embedding with a high retrieval score that rivals the state of the art")}
            {/* Vector Database */}
            {techStackElement('/qdrant_logo.svg','Qdrant logo', "The Qdrant vector store is a high-performance, open-source, scalable, and flexible platform designed for efficient similarity search and retrieval of vector embeddings, offering advantages such as fast query processing, support for various distance metrics, and integration with popular machine learning frameworks.")}
            {/* Distributed Database */}
            {techStackElement('/cassandra_logo.svg','cassandra logo', " Apache Cassandra offers high availability, fault tolerance, scalability, and low-latency performance for handling large volumes of distributed data, making it ideal for real-time, write-intensive applications. The open source database is designed for modern distributed server architecture.")}
            {/* LLM */} 
            {techStackElement('/openai-logomark.svg','Open AI logo', "State of the art Large Language Model with and API to generate the cover letter.")}
            {/* Backend */} 
            {techStackElement('/Node.js_logo.svg','Node js logo', "Node.js offers advantages such as high performance with non-blocking, event-driven architecture, scalability for handling numerous concurrent connections, a vast ecosystem of libraries via npm, seamless full-stack JavaScript development, and strong community support, making it ideal for building fast, scalable, and efficient web applications.")}
          </div>
        </div>
     {/* How it works section */}
     <div className="bg-white opacity-95 rounded-lg p-8 w-full mt-16 mb-16">
          <h2 className="text-3xl font-bold mb-4">How it works</h2>
          {/* Add your "How it works" content here */}
          <img className="w-full" src='/how_it_works.svg' alt='How it work flow chart'/>
        </div>


  </div>
</div>
  )
}
export default LandingPage;