import React, { createContext, useRef, useContext, ReactNode } from 'react';
import { Toast } from 'primereact/toast';

interface ToastContextType {
  showToast: (toastRequest: toastRequestObject) => void;
}

export interface toastRequestObject{
  severity: 'success' | 'info' | 'warn' | 'error'
  summary: string,
  life?: number,
  position?: string,
  detail?: string
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const useToast = (): ToastContextType => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

interface ToastProviderProps {
  children: ReactNode;
}

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const toast = useRef<Toast>(null);

  const showToast = (toastRequest: toastRequestObject) => {
    toast.current?.show({...toastRequest, life: toastRequest.life || 3000});
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      <Toast ref={toast} />
      {children}
    </ToastContext.Provider>
  );
};
