import { Button } from "primereact/button";
import { useState } from "react";
import client from "../utils/axios";
import { useToast } from "../utils/toast_context";
import { sha256 } from "./login";
import { Link, useNavigate } from "react-router-dom";

function SignUp(): JSX.Element {
  interface SIGNUP_ITEM{
    email?: string;
    password?: string;
    password_repeat?: string;
    name?: string, 
    career_position?: string,
    website?: string,
    linked_in?: string,
    twitter?: string,
    street_address?: string,
    city?: string,
    country?: string,
    instagram?: string,

  }

  const [item, setItem] = useState<SIGNUP_ITEM>({});
  const { showToast } = useToast(); 
  const [loadingEmailCheck, setLoadingEmailCheck] = useState<boolean>(false);
  const [emailValid, setEmailValid] = useState<0 | 1 | -1>(0);
  const navigate = useNavigate();


  const validateEmail = async (email:string): Promise<void> => {
    // https://stackoverflow.com/questions/46155/how-can-i-validate-an-email-address-in-javascript
    const emailFormatCheck = !! email.toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    if (emailFormatCheck) {
      setLoadingEmailCheck(true);
      try {
        const emailExists = await client.get('user/checkEmail', {params: {email}})
        setLoadingEmailCheck(false);
        emailExists?.data?.exists ? setEmailValid(-1) : setEmailValid(1);
      } catch (error) {
        showToast({
          severity: 'error', 
          summary: `Failed to check email`
        })
        console.error(error)
        setLoadingEmailCheck(false);
      }
    } else {
      setEmailValid(0);
    }
  };

  const submit = async () => {

    try {
      if (!item.password || !item.email) throw new Error('No email or password given');
      const password_hashed = await sha256(item.password);
      await client.post('user/signup', {email: item.email, password: password_hashed});
      showToast({
        severity: 'success', 
        summary: `Sign up success`
      })
      navigate('/login')
    } catch (error) {
      showToast({
        severity: 'error', 
        summary: `Failed to sign up`
      })
      console.error(error)
    }
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 bg-white rounded-xl shadow-lg">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="flex justify-center">
            <a href="/">
              <img className="h-20" src="/logo_black.svg" alt="logo" />
            </a>
          </div>
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign Up
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                E-mail
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  autoComplete="email"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  value={item.email}
                  onChange={(e) => {
                    setItem({...item, email: e.target.value});
                    validateEmail(e.target.value || '');
                  }}
                />
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                Password
              </label>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  autoComplete="new-password"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  value={item.password}
                  onChange={(e) => setItem({...item, password: e.target.value})}
                />
              </div>
            </div>

            <div>
              <label htmlFor="repeat_password" className="block text-sm font-medium leading-6 text-gray-900">
                Repeat Password
              </label>
              <div className="mt-2">
                <input
                  id="repeat_password"
                  name="repeat_password"
                  type="password"
                  required
                  autoComplete="new-password"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  value={item.password_repeat}
                  onChange={(e) => setItem({...item, password_repeat: e.target.value})}
                />
              </div>
            </div>

            <div className="space-y-2">
              {item.password_repeat !== item.password && (
                <p className="text-sm text-red-600">Passwords do not match!</p>
              )}
              {emailValid === 0 && (
                <p className="text-sm text-red-600">Email is in the wrong format!</p>
              )}
              {emailValid === -1 && (
                <p className="text-sm text-red-600">Email is already in use!</p>
              )}
            </div>

            <div>
              <Button
                type="button"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                label="Sign Up"
                onClick={() => submit()}
                disabled={emailValid !== 1 || loadingEmailCheck || !item.password || item.password_repeat !== item.password}
              />
            </div>
          <p className="mt-10 text-center text-sm text-gray-500">
            Already a user?{' '}
            <Link to="/login" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
              Sign in to your account
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default SignUp;