import { Dialog } from "primereact/dialog"
import {  ReactElement} from "react";
import { UserInfoForm } from "./UserInfoForm";

interface USER_INFO_PROPS{
  visible: boolean;
  setVisible: Function;
}

export interface INSERT_USER_OBJECT{
  name?: string; 
  email?: string; 
  career_position?: string;
  website?: string;
  linked_in?: string;
  twitter?: string;
  street_address?: string;
  city?: string;
  country?: string;
  instagram?: string;
  // user_image_large: BinaryData | undefined, // 196x196 image 
  // user_image_small: BinaryData | undefined, // 80x80 image
}

export function UserInfo({visible, setVisible}:USER_INFO_PROPS):ReactElement{

  const onClose = async () => {
    setVisible(false);
  }

  const onSave = async(success: boolean) => {
    success&&setVisible(false);
  }

  return (
    <Dialog
      style={{ width: '70vw' }} 
      header={<span><i className="pi pi-user"/> USER</span>} 
      visible={visible} 
      onHide={()=>onClose()} >
      <UserInfoForm onSave={onSave} setUser={(user)=>undefined}/>
    </Dialog>
    );
}