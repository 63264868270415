import useAuth from "./useAuth";
import client from "../utils/axios";

export default function useRefreshToken() {
  const {setAuth} = useAuth();

  const refresh =  async ():Promise<string|null> => {
    try {
      const response = await client.get('user/refresh', { withCredentials: true });
      setAuth((prev)=>({...prev, access_token: response.data.access_token}));
      return response?.data?.access_token
    } catch (error) {
      console.error(error)
      return Promise.reject('Error Refreshing / no cookie given');
    }
  }

  return refresh
}