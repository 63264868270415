import { Column, ColumnEditorOptions } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import {  ReactElement, useEffect, useState } from "react";
import { useToast } from "../utils/toast_context";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { Button } from "primereact/button";

interface USER_INFO_FORM_PROPS{
  onSave: (success: boolean)=>void,
  setUser: (user: INSERT_USER_OBJECT) => void
}

export interface INSERT_USER_OBJECT{
  name?: string; 
  email?: string; 
  career_position?: string;
  website?: string;
  linked_in?: string;
  twitter?: string;
  street_address?: string;
  city?: string;
  country?: string;
  instagram?: string;
  // user_image_large: BinaryData | undefined, // 196x196 image 
  // user_image_small: BinaryData | undefined, // 80x80 image
}

export function UserInfoForm({ onSave, setUser }:USER_INFO_FORM_PROPS):ReactElement{
  const { showToast } = useToast(); 
  const axios_private = useAxiosPrivate();

  const [userInfo, setUserInfo] = useState<INSERT_USER_OBJECT>({
    email: undefined,
    name: undefined,
    career_position: undefined,
    website: undefined,
    linked_in: undefined,
    twitter: undefined,
    street_address: undefined,
    city: undefined,
    country: undefined,
    instagram: undefined
  })

  interface SOCIAL_MEDIA_OBJECT{
    social_media: string,
    title: string,
    icon: JSX.Element,
    link: string | undefined
  }

  const social_medias: SOCIAL_MEDIA_OBJECT[] = [
    {social_media: 'instagram', title: 'Instagram', icon: <i className="pi pi-instagram"/>, link: undefined},
    {social_media: 'linked_in', title: 'Linkedin', icon: <i className="pi pi-linkedin"/>, link: undefined},
    {social_media: 'website', title: 'Website', icon: <i className="pi pi-globe"/>, link: undefined},
    {social_media: 'twitter', title: 'Twitter', icon: <i className="pi pi-twitter"/>, link: undefined},
  ];

  const [socialMedias, setSocialMedias] = useState(social_medias);
  const [loadingUserInfo, setLoadingUserInfo] = useState(true);

  const sendUserInfo = async (userInfo: INSERT_USER_OBJECT):Promise<boolean> => {
    try {
      const success = await axios_private
        .post('user/update', userInfo)
      return !!success;
    } catch (error) {
      showToast({
        severity: 'error', 
        summary: `Failed to save user info`
      });
      console.error(error);
      return false;
    }
  }

  const linkEditor = (options: ColumnEditorOptions) => (<InputText 
      type="text" 
      value={options.value} 
      onChange={(e) => {
        onEdit(e.target.value, options.rowData.social_media, options.rowIndex)
        options.editorCallback&&options.editorCallback(e.target.value);
      }} 
      onKeyDown={(e) => e.stopPropagation()} 
      disabled={loadingUserInfo}
    />);

  const onEdit = (newValue:string, social_media:string, rowIndex:number) =>{
    setUserInfo({...userInfo, [social_media]:newValue});
    setUser({...userInfo, [social_media]:newValue});
    const social_medias_array = socialMedias;
    social_medias_array[rowIndex].link = newValue;
    setSocialMedias(social_medias_array);
  }

  const columns = [
    {header: '', field:'icon', editor: undefined},
    {header: 'Social Media', field:'title', editor: undefined},
    {header: 'Link', field:'link', editor: linkEditor },
  ];

  const getUserInfo = async () => {
    setLoadingUserInfo(true);
    try {
      await axios_private
        .get('user/info')
        .then(({data})=>{
          setUserInfo(data as INSERT_USER_OBJECT);
          setUser(data)
          setSocialMedias(socialMedias.map((social)=>({...social, link: data[social.social_media] || undefined})))
          setLoadingUserInfo(false);
        });
    } catch (error) {
      showToast({
        severity: 'error', 
        summary: `Failed to fetch user info for user`,
        life: undefined,
        position: undefined,
        detail: undefined
      });
      console.error(error);
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(()=>{getUserInfo();}, [])


  return (
      <form>
        <div>
          <label htmlFor="name">Name:  </label>
          <InputText id="name" value={userInfo?.name||''} onChange={(e)=>{
            setUserInfo({...userInfo, name:e.target.value||''});
            setUser({...userInfo, name:e.target.value||''});
            }} disabled={loadingUserInfo} />
        </div>
        <div>
          <label htmlFor="career_position" className="career_position">Career Position: </label>
          <InputText id="career_position" value={userInfo?.career_position||''} onChange={(e)=>{
            setUserInfo({...userInfo, career_position:e.target.value||''});
            setUser({...userInfo, name:e.target.value||''});
            }} disabled={loadingUserInfo} ></InputText>
        </div>
        <div>
          <label htmlFor="street_address" className="street_address">Street Address: </label>
          <InputText id="street_address" value={userInfo?.street_address ||''} onChange={(e)=>{
              setUserInfo({...userInfo, street_address:e.target.value||''});
              setUser({...userInfo, name:e.target.value||''});
            }} disabled={loadingUserInfo} ></InputText>
        </div>
        <div>
          <label htmlFor="city" className="city">City: </label>
          <InputText id="city" value={userInfo?.city ||''} onChange={(e)=>{
            setUserInfo({...userInfo, city:e.target.value||''});
            setUser({...userInfo, name:e.target.value||''});
          }} disabled={loadingUserInfo} ></InputText>
        </div>
        <div>
          <label htmlFor="country" className="country">Country: </label>
          <InputText id="country" value={userInfo?.country ||''} onChange={(e)=>{
            setUserInfo({...userInfo, country:e.target.value||''});
            setUser({...userInfo, name:e.target.value||''});
          }} disabled={loadingUserInfo} ></InputText>
        </div> 
        <DataTable value={socialMedias} editMode="cell">
          {columns.map(({header, field, editor},count)=>(
            <Column 
              key={count} 
              header={header} 
              field={field} 
              editor={editor}
              onCellEditComplete={(e)=>onEdit(e.newValue, e.rowData.social_media, e.rowIndex)} />))}
        </DataTable>
        <div className="flex pt-4 justify-content-end">
          <Button label="Save" outlined rounded onClick={()=>sendUserInfo(userInfo).then((success)=>onSave(success))} />
        </div> 
      </form>
    );
}