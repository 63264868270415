import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { InputTextarea } from "primereact/inputtextarea"
import { Dispatch, SetStateAction, useState } from "react"

interface NEW_JOB_LISTING_PROPS{
  visible: boolean,
  setVisible: Dispatch<SetStateAction<boolean>>
  onComplete: (job_listing:string)=>Promise<void>
}

export default function NewJobListing({visible, setVisible, onComplete}: NEW_JOB_LISTING_PROPS) {
  const [jobListing, setJobListing] = useState<string>('');

  return <Dialog visible={visible} onHide={()=>setVisible(false)}>
    <InputTextarea value={jobListing || ''} onChange={(e)=>setJobListing(e.target.value)} autoResize/>
    <Button label="Create" disabled={!jobListing || !jobListing.trim()} onClick={()=>onComplete(jobListing).then(()=>setVisible(false))}  />
  </Dialog>
}