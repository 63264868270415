// theme
import "primereact/resources/themes/lara-light-indigo/theme.css";  
// css
import "primereact/resources/primereact.min.css";                  
// icons
import "primeicons/primeicons.css";                                
// layout (optional)
import "primeflex/primeflex.css";

import './App.css';
import {
  Navigate,
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';
import MainPage from './pages/main_page';
import { ToastProvider } from "./utils/toast_context";
import Login from "./pages/login";
import LandingPage from "./pages/landing_page";
import SignUp from "./pages/signup";
import RequireAuth from "./elements/RequireAuth";

function App():JSX.Element {
  return (
    <div className="App">
      <ToastProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/login"  element={<Login />}/>
            <Route path="/register"  element={<SignUp />}/>
            <Route element={<RequireAuth />}>
              <Route path='/app' element={<MainPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ToastProvider>
  </div>
  );
}

export default App;
