import { Button } from "primereact/button";
import { Column, ColumnBodyOptions } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useToast } from "../utils/toast_context";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import { CoverLetterObject, PromptObject, StepByStepItem } from "./StepByStep";
import { InputTextarea } from "primereact/inputtextarea";
import NewJobListing from "./NewJobListing";
import { RecipientInput } from "./RecipientInput";
import { INSERT_USER_OBJECT } from "./UserInfoForm";
import DropDownTailWind from "../utils/DropDown";

interface CREATE_JOB_LISTING{
  job_title?: string; 
  company_name?: string;
  cover_letter_example?: string;
  cover_letter_example_id?: number; 
  prompt_example?: string;
  prompt_example_id?: number;
  job_listing?: string;
  queries?:string;
  query_results?: string;
  prompt?: string;
  prompt_id?: number;
  results?: string;
  recipient_name?: string;
  recipient_position?: string;
  recipient_street_address?: string;
  recipient_city?: string;
  recipient_country?: string;
  document_name?: string;
  blob_id?: number;
}

export interface COLUMNS_JOB_LISTING extends CREATE_JOB_LISTING{
  job_listing_id: number;
};

interface Generate_PROP{
  standardInput: StepByStepItem,
  exampleCoverLetters: CoverLetterObject[],
  examplePrompts: PromptObject[],
  prompts: PromptObject[],
}

export default function Generate({standardInput, exampleCoverLetters, examplePrompts, prompts}:Generate_PROP) {
  const { showToast } = useToast();
  const axios_private = useAxiosPrivate();
  const [jobListings, setJobListings] = useState<COLUMNS_JOB_LISTING[]>([]);
  const [expandedRows, setExpandedRows] = useState<COLUMNS_JOB_LISTING[]>([]);
  const [showCreateJobListingDialog, setShowCreateJobListingDialog] = useState<boolean>(false);
  const [loadingJobListingId, setLoadingJobListingId] = useState<Record<number,Record<keyof COLUMNS_JOB_LISTING, boolean>>>({});
  const [loadingJobListings, setLoadingJobListings] = useState<boolean>(false);
  const [showRecipientId, setShowRecipientId] = useState<number>(-1)

  const deleteJobListing = async (job_listing_id:number) => {
    try {
      await axios_private.post('listing/delete', {job_listing_id}).then((data)=>{
        return getJobListings();
      })
    } catch (error) {
      showToast({
        severity: 'error', 
        summary: `Failed to delete job listings`
      });
      console.error(error);
    }
  }

  const deleteJobListingTemplate = (job_listing:COLUMNS_JOB_LISTING) => 
    <Button 
      onClick={()=>deleteJobListing(job_listing.job_listing_id)}
      icon='pi pi-trash' 
      rounded 
      outlined 
    />;

  const getJobListing = async (job_listing_id: number,updated_key: keyof COLUMNS_JOB_LISTING) => {
    setLoadingJobListingId({
      ...loadingJobListingId, 
      [job_listing_id]: {
        ...loadingJobListingId[job_listing_id], [updated_key]:true
      }
    });
    try {
      const job_listing: COLUMNS_JOB_LISTING = await axios_private
        .get('listing/readSingle', { params:{job_listing_id} })
        .then((result)=>result.data);
      if (job_listing){
        const jobListingTemp = jobListings;
        const index = jobListingTemp.findIndex((job_listing)=>job_listing_id===job_listing.job_listing_id);
        jobListingTemp[index] = job_listing;
        setJobListings(jobListingTemp);
        setLoadingJobListingId({
          ...loadingJobListingId, 
          [job_listing_id]: {
            ...loadingJobListingId[job_listing_id], [updated_key]:false
          }
        });
      }
      setLoadingJobListingId({})
    } catch (error) {
      showToast({
        severity: 'error', 
        summary: `Failed to get job listings`
      });
      console.error(error);
      setLoadingJobListingId({
        ...loadingJobListingId, 
        [job_listing_id]: {
          ...loadingJobListingId[job_listing_id], [updated_key]:false
        }
      });
    }
  }

  const updateJobListing = async (job_listing: COLUMNS_JOB_LISTING, updated_key: keyof COLUMNS_JOB_LISTING) => {
    try {
      await axios_private
        .post('listing/update', job_listing)
        .then(()=> getJobListing(job_listing.job_listing_id, updated_key))
      showToast({
        severity: "success",
        summary: "Update job listing"
      })
    } catch (error) {
      showToast({
        severity: 'error', 
        summary: `Failed to update job listing`
      });
      console.error(error);
    }
  }

  const saveToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    showToast({
      severity: "success",
      summary: "Copied Text"
    })
  }

  const textTemplateWrapper = (
    job_listing: COLUMNS_JOB_LISTING,
    options: ColumnBodyOptions
  ): React.ReactNode => {
    const text = job_listing[options.field as keyof COLUMNS_JOB_LISTING];
    if (typeof text === 'string')
      return <span>{`${text?.substring(0,75)}...`}
        <Button 
          icon='pi pi-copy' 
          rounded 
          outlined 
          onClick={()=>saveToClipboard(text)} 
        /></span>;
    else return null;
  };
  
  type RESPONSE_TYPE = 'job_listing' | 'query_results' | 'generate' | 'pdf';

  const sendResponse = async (job_listing_id: number, response_type: RESPONSE_TYPE) => {
    try {
      await axios_private.post('listing/response', {job_listing_id, response_type})
    } catch (error) {
      showToast({
        severity: 'error', 
        summary: `Failed to Response`
      });
      console.error(error);
      
    }
  }

  const getResponse = async (job_listing: COLUMNS_JOB_LISTING, user: INSERT_USER_OBJECT) => {
    console.log(user)
    try {
      if ((!job_listing.queries || !job_listing.job_title ) && job_listing.job_listing) {
        await sendResponse(job_listing.job_listing_id, 'job_listing')
          .then(()=>getJobListing(job_listing.job_listing_id, 'job_title'))
      } else if (!job_listing.query_results && job_listing.queries) {
        await sendResponse(job_listing.job_listing_id, 'query_results')
          .then(()=>getJobListing(job_listing.job_listing_id, 'job_title'))
      } else if (
          !job_listing.results && 
          job_listing.company_name && 
          job_listing.job_title && 
          job_listing.query_results &&
          job_listing.prompt &&
          job_listing.prompt_example &&
          job_listing.cover_letter_example &&
          job_listing.job_listing
        ) {
        await sendResponse(job_listing.job_listing_id, 'generate')
          .then(()=>getJobListing(job_listing.job_listing_id, 'results'))
      } else if (
        (!job_listing.blob_id && job_listing.blob_id !== 0 ) && 
        job_listing.results && 
        job_listing.job_title && 
        job_listing.recipient_name &&
        job_listing.recipient_position &&
        user.name &&
        user.career_position
      ) {
        console.log('test');
        await sendResponse(job_listing.job_listing_id, 'pdf')
          .then(()=>getJobListing(job_listing.job_listing_id, 'blob_id'))
      }
    } catch (error) {
      showToast({
        severity: 'error', 
        summary: `Failed to post response`
      });
      console.error(error);
    }

  }

  const checkJobListingForResponse = async (job_listings: COLUMNS_JOB_LISTING[]) => {
    try {
      if (!loadingJobListings)
        await Promise.all([
          job_listings.map((job_listing)=>getResponse(job_listing, standardInput.user_type))
        ])
    } catch (error) {
      showToast({
        severity: 'error', 
        summary: `Failed to check job listings`
      });
      console.error(error);
    }
  }

  const createJobListing = async (standard_input: StepByStepItem, job_listing:string) => {
    const listing: CREATE_JOB_LISTING = {
      job_listing, 
      prompt_example: standard_input.example_prompt.prompt, 
      prompt_example_id: standard_input.example_prompt.prompt_id, 
      prompt: standard_input.prompt.prompt, 
      prompt_id: standard_input.prompt.prompt_id, 
      cover_letter_example: standard_input.example_cover_letter?.cover_letter, 
      cover_letter_example_id: standard_input.example_cover_letter?.cover_letter_id,
    };
    try {
      await axios_private.post('listing/create', listing).then(()=>getJobListings());
    } catch (error) {
      showToast({
        severity: 'error', 
        summary: `Failed to get job listings`
      });
      console.error(error);
    }
  }

  function dropdownTemplate<T=PromptObject|CoverLetterObject>(
    options:T[], 
    id_key:keyof T, 
    value_key: keyof T,
    job_listing: COLUMNS_JOB_LISTING,
    job_listing_id_key: keyof COLUMNS_JOB_LISTING,
    job_listing_value_key: keyof COLUMNS_JOB_LISTING,
    optionLabel: keyof T = 'title' as keyof T,
  ){
    const optionValue = typeof id_key === 'string'?id_key:'' as keyof T;
    const loading_job_listing_id = loadingJobListingId[job_listing.job_listing_id];
    const loading: boolean = loading_job_listing_id && loading_job_listing_id[job_listing_value_key];
    return !loading?<DropDownTailWind
      value={(job_listing[job_listing_id_key] || job_listing[job_listing_id_key] === 0) ? job_listing[job_listing_id_key] : null} 
      options={options.filter((option)=> option[id_key] || option[id_key] === 0)} 
      optionLabel={optionLabel}
      optionValue={optionValue}
      invalid={!(job_listing[job_listing_id_key] || job_listing[job_listing_id_key] === 0)}
      disabled={!!job_listing.results}
      onChange={(e)=>{
        const option = options.find((value)=>value[id_key] === e.target.value);
        if (option)
          updateJobListing(
            {job_listing_id: job_listing.job_listing_id, [job_listing_id_key]: option[id_key], [job_listing_value_key]: option[value_key]},
            job_listing_value_key
          );
      }}
    />:'...'
  }

  function recipientButton(job_listing: COLUMNS_JOB_LISTING) {
    const loading_job_listing_id = loadingJobListingId[job_listing.job_listing_id];
    const loading: boolean = loading_job_listing_id && loading_job_listing_id['recipient_name'];
    return loading?
      '...':
      <Button 
        severity={job_listing.recipient_name?'info':'danger'}
        outlined
        label={job_listing.recipient_name?job_listing.recipient_name:'Missing Recipient'} 
        onClick={()=>{
          setShowRecipientId(job_listing.job_listing_id)
        }}
      />;
  }

  const downloadPDF = async (job_listing_id: number, document_name: string) => {
    try {
      const file = await axios_private
        .get('pdf/blob', {
          params: {job_listing_id}, 
          responseType: 'arraybuffer'}
        ).then(({data})=>
          new Blob([data], {type: 'application/pdf'})
        );
      const link = document.createElement('a');
      link.download = document_name;
      link.href = window.URL.createObjectURL(file);
      link.click()
      window.URL.revokeObjectURL(link.href)
    } catch (error) {
      showToast({
        severity: 'error', 
        summary: `Failed to download PDF!`,
      })
      console.error(error)
    }
  }

  const DownloadPDFButton = (job_listing: COLUMNS_JOB_LISTING) => {
    const document_name = typeof job_listing.document_name === 'string' ? job_listing.document_name : '';
    return (
      <Button 
        label={(job_listing.document_name)} 
        outlined 
        icon="pi pi-download" 
        onClick={()=>downloadPDF(Number(job_listing.job_listing_id), document_name)}
        disabled={!job_listing.blob_id && job_listing.blob_id !== 0}
      />);
  }

  const columnsGenerateCoverLetter = [
    {field: 'expander', header: '', expander: true},
    {field: 'job_title', header: 'Job Title', body: textTemplateWrapper, editor: undefined},
    {
      field: 'prompt_id', 
      header: 'Prompt', 
      body: (job_listing: COLUMNS_JOB_LISTING)=>
        dropdownTemplate<PromptObject>(
          prompts, 
          'prompt_id',
          'prompt',
          job_listing,
          'prompt_id',
          'prompt'
        ),
      editor: undefined
    },
    {
      field: 'cover_letter_example_id', 
      header: 'Cover Letter Example', 
      body: (job_listing: COLUMNS_JOB_LISTING)=>
        dropdownTemplate<CoverLetterObject>(
          exampleCoverLetters, 
          'cover_letter_id',
          'cover_letter',
          job_listing,
          'cover_letter_example_id',
          'cover_letter_example'
        ),
      editor: undefined
    },
    {
      field: 'prompt_example_id', 
      header: 'Prompt Example', 
      body: (job_listing: COLUMNS_JOB_LISTING)=>
        dropdownTemplate<PromptObject>(
          examplePrompts, 
          'prompt_id',
          'prompt',
          job_listing,
          'prompt_example_id',
          'prompt_example'
        ), 
      editor: undefined
    },
    {field: 'recipient_name', header: 'Recipient Name', body: recipientButton, editor: undefined},
    {field: 'results', header: 'Results', body: textTemplateWrapper, editor: undefined},
    {field: 'document_name', header: 'PDF', body: DownloadPDFButton, editor: undefined},
    {field: 'delete', header: '', body:deleteJobListingTemplate, editor: undefined}
  ];
  
  const getJobListings = async () => {
    try {
      setLoadingJobListings(true);
      const job_listings = await axios_private.get('listing/read');
      if (job_listings.data.length) setJobListings(job_listings.data);
      setLoadingJobListings(false);
      await checkJobListingForResponse(job_listings.data);
    } catch (error) {
      showToast({
        severity: 'error', 
        summary: `Failed to get job listings`
      });
      console.error(error);
    }
  }

  useEffect(()=>{
    getJobListings();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const rowExpansionTemplateJobListing = (job_listing: COLUMNS_JOB_LISTING) => {
    return <div>
      <InputTextarea 
        id='text-area-job-listing' 
        style={{width: '100%'}} 
        value={job_listing.job_listing  || ''} rows={5} 
        disabled 
      />
      <InputTextarea 
        id='text-area-results' 
        style={{width: '100%'}} 
        value={job_listing.results  || ''} 
        rows={10} 
        onChange={(e)=>{
          job_listing.results = e.target.value;
          const jobListingsTemp = jobListings;
          const indexJobListing = jobListings.findIndex(({job_listing_id})=>job_listing_id===job_listing.job_listing_id);
          jobListingsTemp[indexJobListing] = {...jobListingsTemp[indexJobListing], results: e.target.value};
          setJobListings(jobListingsTemp);
        }}
      />
    </div>
  }

  return (
    <div className="form" style={{height: '100%', width: '100%'}}>
      {(showRecipientId >= 0) && <RecipientInput job_listing_id={showRecipientId} visible={showRecipientId >= 0} setVisible={(visible)=>visible?null:setShowRecipientId(-1)} onComplete={({job_listing_id})=>getJobListing(job_listing_id, 'recipient_name')}/>}
      {showCreateJobListingDialog && 
        <NewJobListing 
          visible={showCreateJobListingDialog} 
          setVisible={setShowCreateJobListingDialog} 
          onComplete={(job_listing)=>createJobListing(standardInput, job_listing)} />}
      <h2 className='text-xl mb-5 font-bold'>Generate  Cover Letters</h2>
      <DataTable 
        value={jobListings} 
        expandedRows={expandedRows} 
        onRowExpand={(e)=>setExpandedRows([...expandedRows, e.data as COLUMNS_JOB_LISTING])}
        onRowCollapse={(e)=>setExpandedRows(expandedRows.filter(({job_listing_id})=>job_listing_id!==e.data.job_listing_id))}
        rowExpansionTemplate={rowExpansionTemplateJobListing}
        loading={loadingJobListings}
      >
        {columnsGenerateCoverLetter.map(({field, header, editor, body, expander})=>
          <Column 
            field={field} 
            header={header} 
            editor={editor}
            body={body}
            expander={expander}
          />
        )}
      </DataTable>
      <Button 
        label='New Job Listing' 
        outlined 
        rounded 
        style={{width: '100%'}}
        onClick={()=>setShowCreateJobListingDialog(true)}
       />
    </div>
  )
}