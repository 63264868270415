import { useState, DragEvent, useEffect, ReactNode } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable'; 
import { Column, ColumnProps } from 'primereact/column';
import { useToast } from '../utils/toast_context';
import { PiFilePdf } from "react-icons/pi";
import { PiFileTxt } from "react-icons/pi";
import { BsFiletypeJson } from "react-icons/bs";
import { UserInfo } from '../elements/UserInfo';
import { mapSeries } from "async";import useAxiosPrivate from '../hooks/useAxiosPrivate';
import pdfToText from "react-pdftotext"
import SignOut from '../utils/signout';
import StepByStep from '../elements/StepByStep';
import { IconContext } from 'react-icons';
export interface documentObject{
  document_context?: string,
  document_id: number,
  document_name?: string,
  document_type?: string
}

export interface ACTION_OBJECT{
  action?: 'user_info' | 'recipient' | undefined,
  actionData?: unknown | undefined
}

interface FILE_UPLOAD_MESSAGE_OBJECT{
  message_current_file?: string,
  file_number_uploading?: number,
  number_of_files_uploading?: number
}

function MainPage(): JSX.Element{
  const [documents, setDocuments] = useState<documentObject[]>([]);
  const [loadingDocuments, setLoadingDocuments] = useState<boolean>(false);
  const [numberOfDocuments, setNumberOfLoadingDocuments] = useState<number>(10);
  const [filesToUpload, setFilesToUpload] = useState<FILE_UPLOAD_MESSAGE_OBJECT>({});
  const [uploadingFile, setUploadingFile] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(true);
  const [action, setAction] = useState<ACTION_OBJECT>({action: undefined, actionData: undefined });
  const { showToast } = useToast(); 
  const axios_private = useAxiosPrivate();

 

  const postDeleteDocument = async (document_id: number) => {
    try {
      await axios_private.post('documents/deleteDocumentForUserId',{document_id})
        .then(()=> getDocumentsForUser(numberOfDocuments));
    } catch (error) {
      showToast({
        severity: 'error', 
        summary: `Failed to delete document for the user`,
        life: undefined,
        position: undefined,
        detail: undefined
      })
      console.error(error)
    }
  }

  const deleteDocumentTemplate = (rowData: documentObject):JSX.Element => {
    return (<Button 
      icon='pi pi-trash'
      rounded
      outlined
      onClick={()=>postDeleteDocument(rowData.document_id)}
    />);
  }

  const documentNameTemplate = (rowData: documentObject)=>(
  <div className='text-black'>{(typeof rowData.document_name === 'string' )?rowData.document_name.substring(0,27):''}</div>)

  const columnsDocument: ColumnProps[] = [{
    header: 'Type',
    field: 'document_type',
    body: (rowData) => icons_file_type(rowData.document_type),
    style: {width: '5rem'}
  },{
    header: 'Name',
    field: 'document_name',
    body: documentNameTemplate,
    style: {maxWidth: '10rem'}
  }, {
    header: null,
    field: 'delete',
    body: deleteDocumentTemplate,
    style: {width: '5rem'}
  }];

  // type accepted_file_type = 'application/json' | 'application/pdf' |'text/plain'

  type short_file_type = 'JSON' | 'TEXT' |'PDF'

  const ACCEPTED_FILE_LIST = [
    'application/json',
     'application/pdf',
     'text/plain'
    ];

  const MAX_FILE_SIZE = 50000000;

  const getDocumentsForUser = async (numberOfDocumentsToLoad:number=10) => {
    setLoadingDocuments(true);
    try {
      await axios_private
        .get('documents/documentsForUserId', {params: {numberOfDocumentsToLoad}})
        .then((res)=>{
          const data = res.data;
          if (Array.isArray(data)) {
            setDocuments(data);
          }
          setNumberOfLoadingDocuments(numberOfDocumentsToLoad);
          setLoadingDocuments(false);
        })
    } catch (error) {
      showToast({
        severity: 'error', 
        summary: `Failed to get documents for the user`,
        life: undefined,
        position: undefined,
        detail: undefined
      });
      console.error(error);
    }
  };

  const uploadDocuments = async (
    text: string, 
    document_name: string, 
    document_type: string, 
    document_context: string
  ) => {
    try {
      // Convert the text to a Buffer
      const blob = new Blob([text], { type: 'text/plain' });
      // Create a FormData object to send additional metadata along with the buffer
      const formData = new FormData();
      formData.append('file', blob, document_name); // Append buffer as a blob
      formData.append('document_name', document_name);
      formData.append('document_type', document_type);
      formData.append('document_context', document_context);

      // Post the buffer to the backend
      const res = await axios_private.post('documents/upload/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
        }
      ).then(()=>getDocumentsForUser(numberOfDocuments));
      return res;
    } catch (error) {
      showToast({
        severity: 'error', 
        summary: `Failed to upload the document ${document_name}`
      });
    }
  };

  function readFileAsText(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onload = () => {
        resolve(reader.result as string);
      };
  
      reader.onerror = (error) => {
        reject(error);
      };
  
      reader.readAsText(file);
    });
  }

  const onDropHandler = async (event: DragEvent) => {
    event.preventDefault();
    if (event.dataTransfer && !uploadingFile) {
      const filesArray = Array.from(event.dataTransfer.files);
      const filesArrayFiltered = filesArray.filter(
        (file) => ACCEPTED_FILE_LIST.includes(file.type) && file.size < MAX_FILE_SIZE
      );
      const fileNamesArrayUnaccepted = filesArray.filter(
        (file) => !(ACCEPTED_FILE_LIST.includes(file.type) && file.size < MAX_FILE_SIZE)
      ).map(({name})=>name);

      if (fileNamesArrayUnaccepted.length) {
        const errorMessage = `Failed to upload: ${fileNamesArrayUnaccepted.join(', ')}` 
        showToast({
          severity: 'warn', 
          summary: `Only upload document smaller than ${MAX_FILE_SIZE/1000000} MB of the type pdf, json or text`,
          detail: errorMessage
        })
        console.error(errorMessage)
      }
      if (filesArrayFiltered.length){
        // synchronously upload incase only one node is available
        setUploadingFile(true);
        let file_count = 1;
        setFilesToUpload({
          message_current_file: 'Starting Upload', 
          file_number_uploading: file_count, 
          number_of_files_uploading: filesArrayFiltered.length
        });
        await mapSeries(
          filesArrayFiltered, 
          async (file: File, cb: Function) => {
            if (file){
              setFilesToUpload({
                message_current_file: 'Parsing Text from PDF', 
                file_number_uploading: file_count, 
                number_of_files_uploading: filesArrayFiltered.length
              });
              if(file.type === 'application/pdf'){
                await pdfToText(file)
                  .then((text)=>uploadDocuments(text,file.name, file.type, ''))
                  .catch((error) => {
                    console.error("Failed to extract text from pdf")
                    setFilesToUpload({...filesToUpload, message_current_file: 'Failed to parse PDF'})
                  });
              } else if (file.type === 'text/plain' || file.type === 'application/json') {
                await readFileAsText(file).then((text)=>uploadDocuments(text,file.name, file.type, ''));
              }
              setFilesToUpload({});
              file_count += 1;
            }},
            (err, result)=>console.error(result)
          );
      }
    }
    setUploadingFile(false);
    setFilesToUpload({}); 
  };

  // On page load
  useEffect(()=>{
    getDocumentsForUser();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  

  const icons_file_type = (type_file:short_file_type):ReactNode => {
    const file_type_icon: Record<short_file_type, ReactNode>={
      'PDF': <PiFilePdf />,
      'JSON': <BsFiletypeJson />,
      'TEXT': <PiFileTxt />
    }
    return <IconContext.Provider value={{ color: "black", className: "global-class-name" }}><div>{file_type_icon[type_file]}</div></IconContext.Provider>;
  };

  const onAction = (action_prop: ACTION_OBJECT) =>{
    switch (action_prop.action) {
      case 'user_info':
        return <UserInfo 
          visible={action_prop.action === 'user_info'} 
          setVisible={()=>setAction({action: undefined, actionData: undefined})}
          />
      default:
        return null;
    }
  }

  return (
    <div className='container' style={{gridTemplateColumns: visible?"auto 5rem":"100vw 0vw"}}>
      {action.action && onAction(action)}
      <div className='item-header'>
        <div className='logo'>
          <img className="h-full" src="/logo_white.svg" alt="logo" />
        </div>
        <div className='expand'>
          {!visible && <Button icon='pi pi-chevron-left' rounded outlined onClick={()=>setVisible(true)} />}
        </div>
      </div>
        <div
          className='item-sidebar sidenav' 
          id="drop_zone"
          onDrop={onDropHandler}
          style={{width: visible?'25rem':0, marginLeft: visible?'25rem':0}}
          onDragOver={(event) => event.preventDefault()}
        >
          <div className='item-sidebar_close'>
            <Button icon='pi pi-chevron-right' rounded outlined onClick={()=>setVisible(false)} />
          </div>
          <div className='item-sidebar_header'><h2 className='text-2xl font-medium mt-2'>Documents</h2></div>
          <div className='item-sidebar_refresh'>
            <Button
              icon='pi pi-refresh'
              className='item-refresh'
              rounded
              outlined
              onClick={()=>getDocumentsForUser(numberOfDocuments)} />
          </div>
          <div className='item-sidebar_sign_out'>
            <SignOut />
          </div>
          <div className='item-sidebar_user'>
            <Button
              icon='pi pi-user'
              className='sidebar-user'
              rounded
              outlined
              onClick={()=>setAction({action: 'user_info', actionData: undefined})} />
          </div>
          <div className='item-sidebar_table'>
            <DataTable  
              scrollable
              scrollHeight='70vh'
              value={documents} 
              loading={loadingDocuments}
              size='small'
              emptyMessage="Drag and Drop Documents"
            >
              {
                columnsDocument.map(({header, field, body, style})=>
                  <Column key={field} header={header} field={field} body={body} style={style}></Column>
                )}
            </DataTable>
          </div>
          <div className='item-sidebar_load_more'>
            {filesToUpload.number_of_files_uploading && <p>{filesToUpload.message_current_file} - File {filesToUpload.file_number_uploading} of {filesToUpload.number_of_files_uploading}</p>}
            <Button label='Show More Documents' onClick={()=>getDocumentsForUser(numberOfDocuments+10)} disabled={uploadingFile||loadingDocuments}/>
          </div>
      </div>
      <StepByStep width={visible?'calc(100vw - 26rem)':'100vw'} />
    </div>
    );
} 

export default MainPage



