/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { client_private } from "../utils/axios";
import useAuth from "./useAuth";
import useRefreshToken from "./useRefreshToken";
import { AxiosError, InternalAxiosRequestConfig } from "axios";
import { useToast } from "../utils/toast_context";
import { useLocation, useNavigate } from "react-router-dom";
import { signOut } from "../utils/signout";

interface AXIOS_ERROR_WITH_SENT extends InternalAxiosRequestConfig{
  sent?: boolean;
}

export default function useAxiosPrivate(){
  const refresh = useRefreshToken();
  const {auth, setAuth } = useAuth();
  const {showToast} = useToast();
  const navigate = useNavigate();
  const location = useLocation();

  const cb_error = (error: unknown) => {
    showToast({
      severity: 'error', 
      summary: `Failed to Sign Out`,
      life: undefined,
      position: undefined,
      detail: undefined
    })
    console.error(error)
  }

  const cb = () => {
    setAuth({});
    navigate('/login', {state: {from: location}, replace: true });
  }

  // define interceptor 
  // are similar to event listeners need to be attached and the removed again
  useEffect(() => {
    const requestInterceptor = client_private.interceptors.request.use(
      (config) => {
        // First request that does not yet have the header with the access token
        if (!config.headers['Authorization']){
          config.headers['Authorization'] = `Bearer ${auth?.access_token}`;
        }
        return config;
      }, 
      (error) => Promise.reject(error)
    )

    const responseInterceptor = client_private.interceptors.response.use(
      (response) => response, // no error
      async (error:AxiosError) => {
        const prevRequest = error?.config as AXIOS_ERROR_WITH_SENT;
        if (error?.response?.status === 403 && !prevRequest?.sent) {

          prevRequest.sent = true; // is it doesn't loop again and again 
          try {
            const new_access_token = await refresh();
            prevRequest.headers['Authorization'] = `Bearer ${new_access_token}`;
          return client_private(prevRequest);
          } catch (error) {
            console.error(error);
            showToast({
              severity: "error",
              summary: "Login session timed out"
            });
            return signOut(cb, cb_error).then(()=>Promise.reject(error));
          }
          
        }
        return Promise.reject(error);
      }
    )
    // clean up function
    return () => {
      client_private.interceptors.response.eject(responseInterceptor);
      client_private.interceptors.request.eject(requestInterceptor);
    }
  }, [auth, cb, cb_error, location, navigate, refresh, showToast])
  

  return client_private;
}