import { Button } from "primereact/button";
import useAuth from "../hooks/useAuth";

import { useToast } from "../utils/toast_context";
import { client_private } from "./axios";
import { useNavigate } from "react-router-dom";

export const signOut = async (cb: ()=>void, cb_error: (err: unknown) => void): Promise<void> => {
  try {
    await client_private.post('user/logout');
    cb();
  } catch (error) {
    cb_error(error)
  }
}


function SignOut(): JSX.Element {
  const { setAuth } = useAuth();
  const { showToast } = useToast(); 
  const navigate = useNavigate();
  const cb = ()=>{
    setAuth({});
    showToast({
      severity: 'success', 
      summary: `Sign Out`,
      life: undefined,
      position: undefined,
      detail: undefined
    });
    navigate('/landing');
  }

  const cb_error = (error: unknown) => {
    showToast({
      severity: 'error', 
      summary: `Failed to Sign Out`,
      life: undefined,
      position: undefined,
      detail: undefined
    })
    console.error(error)
  }
  
  return <Button icon="pi pi-sign-out" outlined rounded onClick={()=>signOut(cb, cb_error)}/>
}

export default SignOut;